import React, { useState } from "react";
import {
  Sidebar,
  SubMenu,
  Menu,
  MenuItem,
  //useProSidebar
} from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Logo from "../assets/image/2k_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/reducer/handleCart";

function Sidebars() {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const [toggled, setToggled] = useState(false);
  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };
  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const count = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logOut = () => {
    dispatch(logout);
    sessionStorage.removeItem("login");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("data");
    sessionStorage.removeItem("head");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("salesInvoice");
    navigate("/");
    window.location.reload();
  };
  const value = sessionStorage.getItem("user");
  const user = sessionStorage.getItem("data");
  return (
    <div
      className={`${
        location.pathname == "/login" ||
        location.pathname == "/ProductView/Invoice" ||
        location.pathname == "/BillingInvoice" ||
        location.pathname == "/demo"
          ? "side_bar_none"
          : ""
      }`}
    >
      <Sidebar
        className={`app ${toggled ? "toggled" : ""}`}
        style={{ height: "100%", position: "absolute" }}
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
      >
        <main>
          <Menu>
            {/* { */}
            {/* collapsed ? (
              <MenuItem
                icon={"->"}
                // component={<Link to="/" />}
                onClick={handleCollapsedChange}
              ></MenuItem>
            ) :  */}
            {/* ( */}
            <MenuItem
            // suffix={"<-"}
            // component={<Link to="/" />}
            // onClick={handleCollapsedChange}
            >
              <div
                style={{
                  padding: "9px",
                  // textTransform: "uppercase",
                  fontWeight: "bold",
                  fontSize: 14,
                  letterSpacing: "1px",
                }}
              >
                <img
                  src={user ? `data:image/jpeg;base64,${user}` : Logo}
                  className="virtual_world-logo"
                  alt="logo"
                  style={{ width: "30%" }}
                ></img>
              </div>
            </MenuItem>
            {/* )} */}
            <hr />
          </Menu>
          <Menu>
            {value && value === "admin" ? (
              <>
                <MenuItem component={<Link to="/" />}>Dashboard</MenuItem>

                <SubMenu defaultOpen label={"Product"}>
                  <MenuItem component={<Link to="/ProductView/ProductTable" />}>
                    View Product{" "}
                  </MenuItem>
                  <MenuItem component={<Link to="/ProductView" />}>
                    New Product
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Purchase"}>
                  <MenuItem component={<Link to="/Purchase/PurchaseTable" />}>
                    View Purchase
                  </MenuItem>
                  <MenuItem component={<Link to="/Purchase/PurchaseForm" />}>
                    New Purchase
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Sales"}>
                  <MenuItem component={<Link to="/Billing/BillingTable" />}>
                    View Sales
                  </MenuItem>
                  <MenuItem component={<Link to="/BillingForm" />}>
                    New Sales
                  </MenuItem>
                </SubMenu>
                <MenuItem component={<Link to="/" />} onClick={logOut}>
                  Logout
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem component={<Link to="/" />}>Dashboard</MenuItem>

                <SubMenu defaultOpen label={"Product"}>
                  <MenuItem component={<Link to="/ProductView/ProductTable" />}>
                    View Product{" "}
                  </MenuItem>
                  <MenuItem component={<Link to="/ProductView/ProductForm" />}>
                    New Product
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Purchase"}>
                  <MenuItem component={<Link to="/Purchase/PurchaseTable" />}>
                    {" "}
                    View Purchase
                  </MenuItem>
                  <MenuItem component={<Link to="/Purchase/PurchaseForm" />}>
                    {" "}
                    New Purchase
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Sales"}>
                  <MenuItem component={<Link to="/Billing/BillingTable" />}>
                    View Sales
                  </MenuItem>
                  <MenuItem component={<Link to="/Billing/BillingForm" />}>
                    New Sales
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Salary"}>
                  <MenuItem component={<Link to="/salaryView " />}>
                    View Salary{" "}
                  </MenuItem>
                  <MenuItem component={<Link to="/salaryAdd" />}>
                    New Salary
                  </MenuItem>
                </SubMenu>
                <SubMenu defaultOpen label={"Customer"}>
                  <MenuItem component={<Link to="/UserRegisterTable" />}>
                    View Customer
                  </MenuItem>
                  <MenuItem component={<Link to="/UserRegister" />}>
                    New Customer
                  </MenuItem>
                </SubMenu>

                <MenuItem component={<Link to="/" />} onClick={logOut}>
                  Logout
                </MenuItem>
              </>
            )}
          </Menu>
        </main>
      </Sidebar>
    </div>
  );
}
export default Sidebars;
