import React, { useEffect, useState } from "react";
import ProductHeader from "./ProductHeader";
import MyBarChart from "./BarChart";
import { useNavigate } from "react-router-dom";
import DonutChart from "./DonutChart";
import {
  getCreditReport,
  getDataFromStart,
  getDebitReport,
  getPurchasesReport,
  getRevenueReport,
  getSalesReport,
  getOverAllReport,
} from "../api/GlobalApi";
import { useQuery } from "react-query";
export default function DashBoard() {
  const navigate = useNavigate();
  const [BranchValue, setBranchValue] = useState(null);

  // const getOverAllReports = useQuery(
  //   "getRevenueReport",
  //   getOverAllReport
  // );
  // const getRevenueReports = useQuery(
  //   "getRevenueReport",
  //   getRevenueReport
  // );
  // useEffect(()=>{
  //   setValue(value +  1)
  //   if(value === 1){
  //     window.location.reload();
  //   }
  // },[])
  const getSalesReports = useQuery("getSalesReport", getSalesReport);
  const getPurchasesReports = useQuery(
    "getPurchasesReport",
    getPurchasesReport
  );
  const getCreditReports = useQuery("getCreditReports", getCreditReport);
  const getDebitReports = useQuery("getDebitReports", getDebitReport);

  let total = getSalesReports?.data?.data + getPurchasesReports?.data?.data;
  return (
    <>
      <ProductHeader
        label="Dashboard"
        name="--dashboard"
        dashBoardHeader="dashboardMainContainerHeader"
        dashBoardHeaderBranchReport="Dashboard"
        setBranchValue={setBranchValue}
      />
      <div className="dashboard-main-container">
        <div className="dashboard-saleReport-container">
          <div
            className="dashboard-saleReport"
            // onClick={() =>
            //   navigate("/CustomerDashboard", {
            //     state: "SalesDashboard",
            //   })
            // }
          >
            <DonutChart
              color="#7ac8f3"
              value={
                BranchValue ? BranchValue : getSalesReports?.data?.data
                //  getSalesReports?.data?.data
              }
            />
            {/* <div className="sale-report-round">
              <div className="sale-report-innerRound"> 50%</div>
            </div> */}
            <div className="sale-report-text">
              {BranchValue ? "Branch Sales Report" : "Sales Report"}{" "}
            </div>
          </div>
          <div
            className="dashboard-saleReport"
            // onClick={() =>
            //   navigate("/CustomerDashboard", {
            //     state: "Purchase Dashboard",
            //   })
            // }
          >
            <DonutChart
              color="#c386b9"
              value={getPurchasesReports?.data?.data}
            />
            <div className="Purchase-report-text">Purchase Report</div>
          </div>
          <div
            className="dashboard-saleReport"
            // onClick={() =>
            //   navigate("/CustomerDashboard", {
            //     state: "Revenue Dashboard",
            //   })
            // }
          >
            <DonutChart color="#fbb06fcc" value={total} />
            <div className="Revenue-report-text">Over All Report </div>
          </div>
        </div>
        <div className="dashboard-bottom-container">
          <div className="dashboard-overall" style={{ padding: "20px" }}>
            <MyBarChart />
          </div>
          <div className="dashboard-creditDebitReport">
            <div
              className="dashboard-saleReport"
              // onClick={() =>
              //   navigate("/CustomerDashboard", {
              //     state: "Credit Dashboard",
              //   })
              // }
            >
              <DonutChart
                color="#f18b99"
                value={getCreditReports?.data?.data}
              />
              <div className="credit-report-text">Credit Report</div>
            </div>
            <div
              className="dashboard-saleReport"
              // onClick={() =>
              //   navigate("/CustomerDashboard", {
              //     state: "Debit Dashboard",
              //   })
              // }
            >
              <DonutChart color="#1bcfb4" value={getDebitReports?.data?.data} />
              <div className="debit-report-text">Debit Report</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
