import React, { useEffect, useState } from "react";
import TableData from "./TableData";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Goback from "../../src/assets/image/GoBack.svg";
import Logo from "../../src/assets/image/Forward.svg";
import IMAGE from "../../src/assets/image/demo.webp";
import { TablePagination } from "@mui/material";
// import { Navigate, useNavigate } from 'react-router-dom';
import PurchaseViewTable from "./PurchaseViewTable";
import { Navigate, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import { getPurchaseDetail } from "../api/GlobalApi";
const PurchaseViews = () => {
  //  const navigate=useNavigate();

  const { data, isLoading, isError, refetch } = useQuery(
    "data",
    getPurchaseDetail
  );
  // const [navigate]=useNavigate()
  // const [status, setStatus] = useState("All");
  // const [filters, setFilter] = useState(Data);
  // console.log("HHHHHHHHH",data);
  let fetchData = data && data?.data;
  let navigate = useNavigate();
  const [searchResult, setSearchResult] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");

  const handleChange = (e) => {
    setSearchProduct(e.target.value);
  };
  const filteredProducts = fetchData?.filter((product) => {
    return product.productName
      ?.toLowerCase()
      .startsWith(searchProduct?.toLowerCase());
  });
  // console.log("Fetching Data",fetchData)
  // console.log(searchProduct)
  // console.log(searchResult)
  //  useEffect(() => {
  //    setFilter(
  //      status === "All" ? Data:Data .filter((dt) => {
  //       return dt.Status === status
  //      })
  //    );
  //  }, [status]);
  return (
    <>
      <div className="container product_container">
        <div className="container">
          <div className="row">
            <div className="col-1">
              <img src={Goback} onClick={() => navigate(-1)}></img>
            </div>

            <div className="col-7 product_header">
              <h2 className="product_view">Purchase View</h2>
            </div>

            <div className="col-4  pt-3 ">
              <input
                type="search"
                placeholder="Search Product"
                className="purchaseViewSearchField"
                onChange={handleChange}
              ></input>
            </div>
          </div>
        </div>

        <div>
          <PurchaseViewTable
            data={fetchData}
            searchProduct={searchProduct}
            searchResult={filteredProducts}
            isLoading={isLoading}
            isError={isError}
            refetch={refetch}
          />
        </div>
      </div>
    </>
  );
};

export default PurchaseViews;
