import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import Chart from "chart.js/auto";

const DonutChart = (props) => {
  let amount = 38800;
  const [reportValue, setReportValue] = useState("");
  const [chartData, setChartData] = useState({
    labels: ["Sales"],
    datasets: [
      {
        data: [0, 100], // Default data
        backgroundColor: [props?.color, "#fff"],
        borderColor: [props?.color],
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    let value = (props && props?.value) || 0; // Ensure value is a number

    setChartData({
      labels: ["Sales"],
      datasets: [
        {
          data: [props && props?.value, 1],
          backgroundColor: [props?.color, "#fff"],
          borderColor: [props?.color],
          borderWidth: 1,
        },
      ],
    });

    let displayValue;
    if (value >= 1000 && value < 1000000) {
      displayValue = (value / 1000).toFixed(1) + "K";
    } else if (value >= 1000000) {
      displayValue = (value / 1000000).toFixed(1) + "M";
    } else {
      displayValue = value;
    }
    setReportValue(displayValue);
  }, [props?.value, props?.color]);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "70%", // This makes it a donut chart
  };

  return (
    <div
      style={{
        position: "relative",
        height: "200px",
        width: "200px",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <Doughnut data={chartData} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <h3 style={{ color: props?.color }}>{reportValue}</h3>
      </div>
    </div>
  );
};

export default DonutChart;
