import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import PrintIcon from "../assets/image/Print.svg";
import EditIcon from "../assets/image/edit.png";
import DeleteIcon from "../assets/image/trash.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertModal from "./AlertModal";
const ProductTable = (props) => {
  const [rows, rowchange] = useState([]);
  const [page, pageChange] = useState(0);
  const [deleteIndex, setDeleteIndex] = useState([]);
  const [rowperpage, rowperpageChange] = useState(6);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);
  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };
  const navigate = useNavigate();

  const HandlerDelete = async (itemId) => {
    // const confirmDelete = window.confirm('Are you sure you want to delete?');
    // const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (deleteIndex) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_HOST}/purchases/bulk`,
          {
            data: deleteIndex,
          }
        );
        props.refetch();
        setDeleteSuccessfully(true);
        document.getElementById("myCheckbox").checked = false;
        return response.data;
      } catch (error) {
        console.log("Error", error);
      }
    }
    if (deleteConfirmAlert) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_HOST}/purchases/bulk`,
          {
            data: [deleteConfirmAlert],
          }
        );
        props.refetch();
        setDeleteSuccessfully(true);
        return response.data;
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  return (
    <>
      <div className=" me-5 pe-2 deleteBtnCss">
        <button
          onClick={() => {
            setDeleteConfirmAlert(deleteIndex);
          }}
          className="deleteBtn"
          style={{
            display: deleteIndex < 1 ? "none" : "block",
          }}
        >
          Delete
        </button>
      </div>
      <div className="container">
        <div className="row">
          <div className="product_table">
            <TableContainer className="product_table_container_sticky">
              <Table>
                <TableHead className="product_heading_sticky ">
                  <TableRow>
                    <TableCell className="purchase_table_heading_first_sticky">
                      Invoice Number
                    </TableCell>
                    <TableCell className="purchase_table_heading_second_sticky">
                      Purchase Name
                    </TableCell>
                    <TableCell className="purchase_table_heading_third_sticky">
                      Quantity
                    </TableCell>
                    <TableCell className="product_table_heading">
                      Purchase Date
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Purchase Amount
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Transport
                    </TableCell>

                    <TableCell className="purchase_table_heading">
                      GST
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Other Amount
                    </TableCell>

                    <TableCell className="purchase_table_heading">
                      Total Amount
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Transaction Mode
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Transaction Status
                    </TableCell>
                    <TableCell className="purchase_table_heading">
                      Edit / Delete
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props && props?.isLoading ? (
                    <TableRow style={{ height: "180PX", position: "relative" }}>
                      <div class="wrapper">
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <div class="shadow"></div>
                        <span>Loading</span>
                      </div>
                    </TableRow>
                  ) : props && props?.isError ? (
                    <TableRow>
                      <p
                        style={{
                          color: "#000",
                          fontSize: "18px",
                          fontWeight: 800,
                        }}
                      >
                        No Network...
                      </p>
                    </TableRow>
                  ) : props.searchProduct?.length === 0 ? (
                    props.data
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((val, i) => {
                        return (
                          <>
                            <TableRow key={i}>
                              <TableCell className="purchase_table_data_first_sticky">
                                {val.invoiceNumber}
                              </TableCell>
                              <TableCell className="purchase_table_data_second_sticky">
                                {val.productName}
                              </TableCell>
                              <TableCell className="purchase_table_data_third_sticky">
                                {val.quantity}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.purchaseDate}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.purchaseAmount}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.transport}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.gst}%
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.otherAmount}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.total}
                              </TableCell>
                              {/* <TableCell className="purchase_table_data">
                                {val.invoiceNumber}
                              </TableCell> */}
                              <TableCell className="purchase_table_data">
                                {val.transactionMode}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.transactionStatus}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                <img
                                  src={EditIcon}
                                  alt=""
                                  className="product_edit_icon"
                                  onClick={() =>
                                    navigate("/Purchase/PurchaseForm", {
                                      state: val,
                                    })
                                  }
                                ></img>
                                <input
                                  id="myCheckbox"
                                  onClick={() => {
                                    setDeleteIndex([...deleteIndex, val.id]);
                                  }}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                  }}
                                  type="checkBox"
                                />
                                <img
                                  style={{ marginBottom: "12px" }}
                                  src={DeleteIcon}
                                  alt="deleteIcon"
                                  className="product_delete_icon"
                                  onClick={() => setDeleteConfirmAlert(val.id)}
                                ></img>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) : props.searchResult?.length > 0 ? (
                    props.searchResult
                      ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                      .map((val, i) => {
                        return (
                          <>
                            <TableRow key={i}>
                              <TableCell className="purchase_table_data_first_sticky">
                                {val.invoiceNumber}
                              </TableCell>
                              <TableCell className="purchase_table_data_second_sticky">
                                {val.productName}
                              </TableCell>
                              <TableCell className="purchase_table_data_third_sticky">
                                {val.quantity}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.purchaseDate}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.purchaseAmount}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.transport}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.gst}%
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.otherAmount}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.total}
                              </TableCell>
                              {/* <TableCell className="purchase_table_data">
                                {val.invoiceNumber}
                              </TableCell> */}
                              <TableCell className="purchase_table_data">
                                {val.transactionMode}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                {val.transactionStatus}
                              </TableCell>
                              <TableCell className="purchase_table_data">
                                <img
                                  src={EditIcon}
                                  alt=""
                                  className="product_edit_icon"
                                  onClick={() =>
                                    navigate("/Purchase/PurchaseForm", {
                                      state: val,
                                    })
                                  }
                                ></img>
                                <input
                                  id="myCheckbox"
                                  onClick={() => {
                                    setDeleteIndex([...deleteIndex, val.id]);
                                  }}
                                  style={{
                                    width: "20px",
                                    height: "20px",
                                    marginRight: "10px",
                                  }}
                                  type="checkBox"
                                />
                                <img
                                  style={{ marginBottom: "12px" }}
                                  src={DeleteIcon}
                                  alt="deleteIcon"
                                  className="product_delete_icon"
                                  onClick={() => setDeleteConfirmAlert(val.id)}
                                ></img>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                  ) : (
                    <TableRow>
                      {/* <TableCell style={{width:'100%'}}> */}

                      <h4 style={{ width: "205%" }}>No record found</h4>
                      {/* </TableCell> */}
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>

      <div className="container footer_section">
        {props.searchProduct?.length === 0 ? (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} of{" "}
                {Number(Math.ceil(props.data?.length / rowperpage))} pages{" "}
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props.data?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props.data?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        ) : props.searchProduct?.length !== 0 ? (
          <div className="row mt-3">
            <div className="col-6">
              <p className="product_paragraph_footer">
                Showing {page + 1} of{" "}
                {Number(Math.ceil(props.searchResult?.length / rowperpage))}{" "}
                pages{" "}
              </p>
            </div>
            <div className="col-6">
              <TablePagination
                rowsPerPageOptions={[props.searchResult?.length]}
                rowsPerPage={rowperpage}
                page={page}
                count={props.searchResult?.length}
                component="div"
                onPageChange={handlepageChange}
                onRowsPerPageChange={handlerowsperpageChange}
              ></TablePagination>
            </div>
          </div>
        ) : (
          <h4>No data found</h4>
        )}
      </div>
      <AlertModal
        deleteConfirmAlert={deleteConfirmAlert}
        setDeleteConfirmAlert={setDeleteConfirmAlert}
        setDeleteSuccessfully={setDeleteSuccessfully}
        deleteSuccessfully={deleteSuccessfully}
        yesOnClick={() => {
          setConfirmAlert(true);
          setDeleteConfirmAlert(false);
          HandlerDelete();
        }}
      />
    </>
  );
};

export default ProductTable;
