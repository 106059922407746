import React, { useEffect } from "react";
import BackIcon from "../assets/productViewImages/Back-icon.png";
import PrintIcon from "../assets/image/Print.svg";
import { useNavigate } from "react-router-dom";
import EditIcon from "../assets/image/edit.png";
import DeleteIcon from "../assets/image/trash.png";
import companyLogo from "../assets/image/2k_logo.svg";
import { useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
} from "@mui/material";
import { useQuery } from "react-query";
import { getCustomerRegister } from "../api/GlobalApi";
import axios from "axios";
import AlertModal from "./AlertModal";
export default function CustomerRegisterTable(props) {
  const navigate = useNavigate();
  const [rows, rowchange] = useState([]);
  const [page, pageChange] = useState(0);
  const [rowperpage, rowperpageChange] = useState(6);
  // const [deleteAlert, setDeleteAlert] = useState(false);
  const [deleteConfirmAlert, setDeleteConfirmAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [deleteSuccessfully, setDeleteSuccessfully] = useState(false);

  const adminUser = sessionStorage.getItem("user");

  let handlepageChange = (event, newpage) => {
    pageChange(newpage);
  };
  let handlerowsperpageChange = (event) => {
    rowperpageChange(+event.target.value);
    pageChange(0);
  };

  const { data, isLoading, isError, refetch } = useQuery(
    "customerResisterTable",
    getCustomerRegister
  );

  let details = data && data?.data ? data && data?.data : [];

  const HandlerDelete = async (itemId) => {
    if (deleteConfirmAlert) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_HOST}/companies/${deleteConfirmAlert}`
        );
        refetch();
        setDeleteSuccessfully(true);
      } catch (error) {
        console.log("Error", error);
      }
      console.log("Deleted!");
    }
  };

  const [searchName, setSearchName] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const handleChange = (e) => {
    setSearchName(e.target.value);
  };
  const filteredProducts = details?.filter((product) => {
    return product.customerName
      ?.toLowerCase()
      .startsWith(searchName?.toLowerCase());
  });
  return (
    <>
      <div className="customerRegisterMainBlock">
        <div className="billingViewMainContainer-header">
          <div>
            <img
              className="billingView-backIcon"
              onClick={() => navigate(-1)}
              src={BackIcon}
              alt="Go Back"
            />
          </div>

          <div className="customerViewHeadSection">
            <div>
              <h3 className="customerViewHeading">Customer Register Table</h3>
            </div>
            <div>
              <input
                type="search"
                placeholder="Search Name"
                onChange={handleChange}
                className="customerViewSearchField"
              ></input>
            </div>
          </div>
        </div>
        <div className="customer-Table-container">
          <TableContainer className="product_table_container_sticky">
            <Table>
              <TableHead className="product_heading_sticky">
                <TableRow>
                  <TableCell className="purchase_table_heading_first_sticky">
                    Id
                  </TableCell>
                  <TableCell className="purchase_table_heading_second_sticky">
                    Customer Name
                  </TableCell>
                  <TableCell className="purchase_table_heading_third_sticky">
                    Phone Number
                  </TableCell>
                  <TableCell className="product_table_heading_fourth_sticky">
                    Alt Phone Number
                  </TableCell>
                  {adminUser === "superAdmin" ? (
                    <TableCell className="product_table_heading">
                      Status
                    </TableCell>
                  ) : (
                    " "
                  )}

                  <TableCell className="product_table_heading">
                    Company Name
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Company Address
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Customer PinCode
                  </TableCell>
                  <TableCell className="product_table_heading">
                    {" "}
                    Company Type
                  </TableCell>
                  <TableCell className="product_table_heading">
                    {" "}
                    Company Logo
                  </TableCell>
                  <TableCell className="product_table_heading">
                    GST Number
                  </TableCell>
                  <TableCell className="product_table_heading">
                    Website
                  </TableCell>
                  <TableCell className="product_table_heading">
                    E-mail
                  </TableCell>
                  <TableCell className="product_table_heading">Role</TableCell>
                  <TableCell className="product_table_heading">
                    Edit / Delete
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow style={{ height: "180PX", position: "relative" }}>
                    <div class="wrapper">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="shadow"></div>
                      <div class="shadow"></div>
                      <div class="shadow"></div>
                      <span>Loading</span>
                    </div>
                  </TableRow>
                ) : isError ? (
                  <TableRow>
                    <p
                      style={{
                        color: "#000",
                        fontSize: "18px",
                        fontWeight: 800,
                      }}
                    >
                      No Network...
                    </p>
                  </TableRow>
                ) : searchName?.length === 0 ? (
                  details
                    ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                    .map((e) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell className="product_table_data_first_sticky">
                              {e.id}
                            </TableCell>
                            <TableCell className="product_table_data_second_sticky">
                              {e.customerName}
                            </TableCell>
                            <TableCell className="product_table_data_third_sticky">
                              {e.customerPhno}
                            </TableCell>
                            <TableCell className="product_table_data_four_sticky">
                              {e.customeralternativePhno}
                            </TableCell>
                            {adminUser === "superAdmin" ? (
                              <TableCell className="purchase_table_data">
                                {e.status}
                              </TableCell>
                            ) : (
                              " "
                            )}
                            <TableCell className="purchase_table_data">
                              {e.shopName}
                            </TableCell>
                            <TableCell className="sales_table_data--productName">
                              {e.companyAddress}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.companyPincode}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.companyType}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {
                                <img
                                  src={`data:image/jpeg;base64,${e.companyLogo}`}
                                  alt="companyLogo"
                                  style={{ width: "30%" }}
                                />
                              }
                              {/* {e.companyLogo} */}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.gstNumber}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.website}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.emailId}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.role}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              <img
                                src={EditIcon}
                                className="sales_edit_icon"
                                onClick={() =>
                                  navigate("/UserRegister", { state: e })
                                }
                                alt="EditIcon"
                              />
                              <img
                                src={DeleteIcon}
                                className="mb-2 sales_delete_icon"
                                onClick={() => setDeleteConfirmAlert(e.id)}
                                alt=""
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                ) : filteredProducts?.length > 0 ? (
                  filteredProducts
                    ?.slice(page * rowperpage, page * rowperpage + rowperpage)
                    .map((e) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell className="product_table_data_first_sticky">
                              {e.id}
                            </TableCell>
                            <TableCell className="product_table_data_second_sticky">
                              {e.customerName}
                            </TableCell>
                            <TableCell className="product_table_data_third_sticky">
                              {e.customerPhno}
                            </TableCell>
                            <TableCell className="product_table_data_four_sticky">
                              {e.customeralternativePhno}
                            </TableCell>
                            {adminUser === "superAdmin" ? (
                              <TableCell className="purchase_table_data">
                                {e.status}
                              </TableCell>
                            ) : (
                              " "
                            )}
                            <TableCell className="purchase_table_data">
                              {e.shopName}
                            </TableCell>
                            <TableCell className="sales_table_data--productName">
                              {e.companyAddress}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.companyPincode}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.companyType}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {
                                <img
                                  src={`data:image/jpeg;base64,${e.companyLogo}`}
                                  alt="companyLogo"
                                  style={{ width: "30%" }}
                                />
                              }
                              {/* {e.companyLogo} */}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.gstNumber}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.website}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.emailId}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              {e.role}
                            </TableCell>
                            <TableCell className="purchase_table_data">
                              <img
                                src={EditIcon}
                                className="sales_edit_icon"
                                onClick={() =>
                                  navigate("/UserRegister", { state: e })
                                }
                                alt="EditIcon"
                              />
                              <img
                                src={DeleteIcon}
                                className="mb-2 sales_delete_icon"
                                onClick={() => setDeleteConfirmAlert(e.id)}
                                alt=""
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })
                ) : (
                  <h4>No record found</h4>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="container footer_section">
            {searchName?.length === 0 ? (
              <div className="row mt-3">
                <div className="col-6">
                  <p className="product_paragraph_footer">
                    Showing {page + 1} of{" "}
                    {Math.ceil(details?.length / rowperpage)} pages
                  </p>
                </div>
                <div className="col-6">
                  <TablePagination
                    rowsPerPageOptions={[details?.length]}
                    rowsPerPage={rowperpage}
                    page={page}
                    count={details?.length}
                    component="div"
                    onPageChange={handlepageChange}
                    onRowsPerPageChange={handlerowsperpageChange}
                  ></TablePagination>
                </div>
              </div>
            ) : searchName?.length !== 0 ? (
              <div className="row mt-3">
                <div className="col-6">
                  <p className="product_paragraph_footer">
                    Showing {page + 1} of{" "}
                    {Math.ceil(searchResult?.length / rowperpage)} pages
                  </p>
                </div>
                <div className="col-6">
                  <TablePagination
                    rowsPerPageOptions={[searchResult?.length]}
                    rowsPerPage={rowperpage}
                    page={page}
                    count={searchResult?.length}
                    component="div"
                    onPageChange={handlepageChange}
                    onRowsPerPageChange={handlerowsperpageChange}
                  ></TablePagination>
                </div>
              </div>
            ) : (
              <h5>No Record Founded</h5>
            )}
          </div>
        </div>
      </div>
      <AlertModal
        deleteConfirmAlert={deleteConfirmAlert}
        setDeleteConfirmAlert={setDeleteConfirmAlert}
        setDeleteSuccessfully={setDeleteSuccessfully}
        deleteSuccessfully={deleteSuccessfully}
        yesOnClick={() => {
          setConfirmAlert(true);
          setDeleteConfirmAlert(false);
          HandlerDelete();
        }}
      />
    </>
  );
}
