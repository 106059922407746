import React, { useEffect, useState } from "react";
import Button from "./Button";
import {
  useForm,
  SubmitHandler,
  Controller,
  useFieldArray,
} from "react-hook-form";
import InputField from "./InputField";
import ProductHeader from "./ProductHeader";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import axios, { Axios } from "axios";
import { useMutation, useQuery } from "react-query";
import { translate } from "@vitalets/google-translate-api";
import addIcon from "../assets/image/Add.svg";
import dltIcon from "../assets/image/Delete.svg";
import deleteIcon from "../assets/image/deleteIcon.svg";
import Add from "../assets/image/Add.svg";
import AlertModal from "./AlertModal";

export default function AddProduct() {
  // const dispatch=useDispatch()
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userForm: [
        {
          productNameEnglish: "",
          productImage: "",
          quantity: "",
          quantityType: "",
          unitPrice: "",
          stock: "",
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: "userForm",
    control,
  });
  const [open, setOpen] = useState(false);
  const [UpdateOpen, setUpdateOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const [base64Image, setBase64Image] = useState(null);
  const navigate = useNavigate();

  const { state } = useLocation();
  let base64String = state && state?.productImage;
  useEffect(() => {
    Array(state).map((ele, ind) => {
      setValue(`userForm.${ind}.productNameEnglish`, ele?.productNameEnglish);
      setValue(`userForm.${ind}.productImage`, ele?.productImage);
      setValue(`userForm.${ind}.productNameTamil`, ele?.productNameTamil);
      setValue(`userForm.${ind}.quantity`, ele?.quantity);
      setValue(`userForm.${ind}.quantityType`, ele?.quantityType);
      setValue(`userForm.${ind}.discountOnUnitPrice`, ele?.discountOnUnitPrice);
      setValue(`userForm.${ind}.offerSelectFromTo`, ele?.offerSelectFromTo);
      setValue(`userForm.${ind}.unitPrice`, ele?.unitPrice);
      setValue(`userForm.${ind}.actualUnitPrice`, ele?.actualUnitPrice);
      setValue(`userForm.${ind}.stock`, ele?.stock);
    });
  }, [state]);

  const { mutate, isLoading, isError, isSuccess } = useMutation(
    (postData) =>
      axios.post(`${process.env.REACT_APP_HOST}/products/addProduct`, postData),
    {
      onSuccess: (data) => {
        setOpen("Product has been successfully added.");
      },
      onError: (error) => {
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );

        console.error("Mutation failed:", error);
      },
    }
  );
  const updata = useMutation(
    (postData) =>
      axios.put(
        `${process.env.REACT_APP_HOST}/products/${state?.id}`,
        postData
      ),
    {
      onSuccess: (data) => {
        setUpdateOpen("Product has been successfully updated.");
      },
      onError: (error) => {
        console.error("Mutation failed:", error);
        setErrorOpen(
          error &&
            error?.response &&
            error?.response?.data &&
            error?.response?.data
        );
      },
    }
  );
  const Submit = async (data) => {
    if (state && state?.id) {
      updata.mutate(data?.userForm[0]);
    } else {
      mutate(data?.userForm);
    }
  };

  const { userForm, productNameEnglish } = watch();

  const handleEnglishChange = async (e) => {
    const text = e.target.value;
    try {
      const translation = await translate(text, { to: "ta" });
      setValue("productNameTamil", translation);
    } catch (error) {
    }
  };
  const [isImages, setIsImages] = useState(state && state.productImage);

  const handlerImage = () => {
    const confirmDelete = window.confirm("Are you want to Change the Image?");
    if (confirmDelete) {
      setIsImages(false);
    }
  };

  let { discountOnUnitPrice } = watch();
  let { quantity } = watch();
  let { unitPrice } = watch();

  const productDivValue = quantity * unitPrice - discountOnUnitPrice;

  useEffect(() => {
    setValue("actualUnitPrice", productDivValue);
  }, [productDivValue]);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <div className="main-container">
      <ProductHeader back={true} productView={true} label="Add Product" />

      <div
        className="input-container"
        style={{
          height: "600px",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <form
          onSubmit={handleSubmit(Submit)}
        >
          <div className="d-flex px-1">
            <div style={{ display: "flex" }}>
              <div>
                {fields.map((field, index) => {
                  const handleImageInputChange = (event) => {
                    const file = event.target.files[0];
                    if (file) {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        const base64String = reader.result;
                        setValue(
                          `userForm.${index}.productImage`,
                          base64String
                        );
                      };
                      reader.onerror = (error) => {
                        console.error(
                          "Error occurred while converting image to base64:",
                          error
                        );
                      };
                    }
                  };
                  return (
                    <div key={field.id}>
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            display: "flex",
                            borderBottom: "2px dashed black",
                            paddingBottom: "10px",
                            width: "100%",
                          }}
                        >
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Product Name{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>
                            <Controller
                              control={control}
                              name={`userForm.${index}.productNameEnglish`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  type="text"
                                  addProductInputCss="addProductInputCss"
                                  onChange={(e) => handleEnglishChange(e)}
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Unit Price{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>{" "}
                            </label>
                            <Controller
                              control={control}
                              name={`userForm.${index}.unitPrice`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  type="number"
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Quantity{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>

                            <Controller
                              control={control}
                              name={`userForm.${index}.quantity`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  type="number"
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Quantity Type
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>

                            <Controller
                              control={control}
                              name={`userForm.${index}.quantityType`}
                              render={(field) => (
                                <InputField
                                  {...field}
                                  addProductInputCss="addProductInputCss"
                                  type="text"
                                />
                              )}
                              rules={{ required: true }}
                            />
                          </div>
                          <div className="ProductDivField">
                            <label className="ProductLabel">
                              Stock{" "}
                              <span style={{ color: "red", fontSize: "14px" }}>
                                *
                              </span>
                            </label>
                            <div>
                              <Controller
                                control={control}
                                name={`userForm.${index}.stock`}
                               
                                  render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                                  <select
                                    {...field}
                                    style={
                                      error ? { borderColor: "red" } : {}
                                    }
                                    value={value}
                                    onChange={onChange}
                                    className=" text-capitalize addProductInputCss"
                                  >
                                    <option value=""></option>
                                    <option value="Available" >
                                      Available
                                    </option>
                                    <option value="OutOfstock">
                                      Out Of stock
                                    </option>
                                  </select>
                                )}
                                rules={{ required: true }}
                              />
                            </div>
                          </div>
                          <div
                            style={{ height: "100px" }}
                            className="d-flex align-items-center"
                          >
                            {/* :<></>} */}
                            <div>
                              {index < 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div
                                    style={{
                                      height: "100px",
                                      display: "flex",
                                      alignItems: "end",
                                    }}
                                  >
                                    <img
                                      src={Add}
                                      alt=""
                                      className=" BillingAdd-AddBtn"
                                      onClick={() => {
                                        append({
                                          // Product: "select",
                                          // UnitPrice: 0,
                                          // DiscountPrice: 0,
                                          // Quantity: 0,
                                          // QuantityType: 0,
                                          // ActualUnitPrice: 0,
                                        });
                                      }}
                                    ></img>
                                  </div>
                                </>
                              )}
                            </div>
                            <img
                              style={{
                                width: "40px",
                                height: "45px",
                                display: index === 0 ? "none" : "block",
                                marginTop: "50px",
                              }}
                              src={deleteIcon}
                              alt="delete"
                              {...register(`userForm.${index}.image`)}
                              onClick={() => remove(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
            </div>
          </div>
          <div>
            <button className="mt-4 ms-3 inputSubmit-Btn" type="submit">
              {isLoading ? (
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </form>
      </div>
      <AlertModal
        open={open}
        setOpen={setOpen}
        // handleClose={handleClose}
        setUpdateOpen={setUpdateOpen}
        UpdateOpen={UpdateOpen}
        navigate={"/ProductView/ProductTable"}
        setErrorOpen={setErrorOpen}
        errorOpen={errorOpen}
      />
    </div>
  );
}
